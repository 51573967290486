import React, {useState, useEffect} from 'react'
import {db} from "../components/firebase";
import {doc, getDoc, setDoc, addDoc, collection, 
    onSnapshot, query, deleteDoc, updateDoc, 
    arrayUnion, FieldValue, serverTimestamp, 
    getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
import {AddRepuestoState} from './dbfirebase'
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import {OrdenModal} from './modal'


export const GenerarAutorizaciones = () => {

    const [dato, setDatos] = useState('')
    const [filas, setFilas] = useState()
    const [remitosPendientes, setRemitosPendientes] = useState()
     // datos del pedido seleccionado
     const [documentInfo, setDocumentInfo] = useState({})

    //Funcion que esta a la escucha de los datos en firebase, solo lo pude hacer correr aqui.
    useEffect(()=>{ 
        const actualdate = new Date()
        const prevdate = new Date(actualdate)
        prevdate.setDate(actualdate.getDate() - 90)

        let unsubscribe = null
        const fetchData = async () => {
            try {
                const docRef = query(collection(db, 'Ordenes'), where("TimeStamp", ">", prevdate));
                unsubscribe = await onSnapshot(docRef, (query) => {
                    let dato = [];
                    query.forEach(doc => {
                        const d = doc.data()
                        dato.push({...d})
                    });
                    
                    setDatos(dato)
                })
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
        
        // return () => {
        //     if (unsubscribe){
        //         unsubscribe()
        //     }
        // }
    },[])

    useEffect(()=>{
        let filas = [];
        // Verificar si existe el campo "Repuestos" y si tiene valores
        dato && dato.forEach((dato)=>{
        
            if (
                dato.Repuestos &&
                Array.isArray(dato.Repuestos) &&
                dato.Repuestos.length > 0 &&
                dato.Taller !== 'Goldmund (central)' &&
                dato?.EnviadoAGoldmund !== true)
                {
                dato.Repuestos.forEach((repuesto) => {
                    if (repuesto.UltEstado === 'Pendiente' || repuesto.UltEstado === 'En consulta') {
                        const fila = {
                            Fecha: dato.Fecha,
                            UUID: dato.UUID,
                            Estado: repuesto.Estado,
                            UltEstado: repuesto.UltEstado,
                            FechaAct: repuesto.Ult_Act,
                            iddoc: dato.iddoc,
                            Taller: dato.Taller,
                            Falla: dato.Desperfecto_declarado,
                            Direccion: dato.Direccion,
                            Cliente: dato.Cliente,
                            CuitDni_cli: dato.CuitDni_cli,
                            Domicilio_cli: dato.Domicilio_cli,
                            Localidad_cli: dato.Localidad_cli,
                            Provincia_cli: dato.Provincia_cli,
                            Telefono_cli: dato.Telefono_cli,
                            Email_cli: dato.Email_cli,
                            Articulo: repuesto.Articulo,
                            Producto: dato.Articulo,
                            Descripcion: repuesto.Descripcion,
                            Cantidad: repuesto.cantidad,
                            Garantia: dato.Garantia,
                            Permiso: dato.Permiso,
                            NumFactura: dato.NumFactura,
                            FechaCompra: dato.FechaCompra,
                            RazonSocialCli: dato.RazonSocialCli,
                            Transporte: dato.Transporte,
                            OrdenTaller: dato.Num_Os,
                            id: repuesto.id
                        }
                        filas.push(fila);
                    }
                
            });
            }
        })
        setFilas(filas)
        setRemitosPendientes(filas.filter((etiqueta) => etiqueta.UltEstado === "Pendiente" || etiqueta.UltEstado === "Observacion" || etiqueta.UltEstado === "En consulta"));
    },[dato])

    // Agrupar las filas por lote
    const filasAgrupadas = filas && filas.reduce((acc, item) => {
        if (!acc[item.iddoc]) {
        acc[item.iddoc] = [];
        }
        acc[item.iddoc].push(item);
        return acc;
    }, {});
    
    const handleClick = async (items) => {
        for (const it of items) {
            await AddRepuestoState(it, 'Autorizado');
        }
    };

    const handleClickState = async (items, state) => {
        for (const it of items) {
            await AddRepuestoState(it, state);
        }
    }

    const downloadFile = (url) => {
        if (!url || typeof url !== 'string'){
            console.log("URL no valida")
        }else{
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.target = "_blank";
            // anchor.download = '';
            anchor.click();
        }        
    }
    
    const showImageModal = async (rutaImagen) => {
        const extensions = ['jpg', 'jpeg', 'png', 'pdf', 'jfif'];
        try {
            const storage = getStorage();
            let imageUrl = null;
            for (const extension of extensions) {
                try {
                    const imagenRef = ref(storage, rutaImagen + '.' + extension);
                    imageUrl = await getDownloadURL(imagenRef);
                    // Si encontramos la imagen, salimos del bucle
                    break;
                } catch (error) {
                    // Si hay un error, probamos con la siguiente extensión
                }
            }
            if (imageUrl) {   
                downloadFile(imageUrl)
            } else {
                console.error("No se pudo encontrar la imagen.");
            }
        } catch (error) {
            console.error("Error al obtener la imagen:", error);
            // Manejar el error adecuadamente
        }
    }

    //Setea el hook productInfo para renderizar un modal
    const productDetail = (rowData) => {
        setDocumentInfo(dato.filter((f) => f.iddoc.toString() === rowData.iddoc)[0])
    }
    
    return(
        <div>
            <h3>Generar Autorizaciones</h3>
            <div className="container" style={{marginTop: '50px'}}>
            <OrdenModal data = {documentInfo}/>
            {filasAgrupadas && Object.entries(filasAgrupadas).map(([lote, items], index) => (
            <div className="accordion" key={index}>
                <div className="card">
                <div className=" row card-header" id={`heading-${lote}`} 
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${lote}`}
                    aria-expanded="true"
                    aria-controls={`collapse-${lote}`}>
                    <div className={`col alert ${items[0].UltEstado === "Observacion"? 'alert-warning':items[0].UltEstado === "En consulta"?'alert-danger':""}`} role="alert">
                    {/* <div className='col'>         */}
                        <h2>
                            {items[0].Taller}
                        </h2>
                        <label>
                            Fecha: {items[0].Fecha}
                        </label>
                        <p>
                            Dirección: {items[0].Direccion}, Transporte: {items[0].Transporte}
                        </p>
                        <p>
                            Orden: {items[0].OrdenTaller}
                        </p>
                        <p>
                            <a href="#" className="detailsLink" onClick={() => { productDetail(items[0]) }} data-bs-toggle="modal" data-bs-target="#Modal">
                                Ver orden
                            </a>
                        </p>
                        <div className='row'>
                            <div className='col '>
                                <button className="btn btn-secondary" onClick={() => showImageModal(`/factura_de_compras/${items[0].iddoc}_${items[0].FechaCompra}_${items[0].RazonSocialCli}_${items[0].NumFactura}`)}>Ver factura</button>
                            </div>
                        </div>
                    </div>
                    {items[0]?.Permiso === 'Garante'?
                        <div className='col'>
                            <h4>
                                Cliente: {items[0].Cliente}
                            </h4>
                            {/* Ver de colocar la fecha de actualizacion de repuesto con FechaAct */}
                            <label>
                                Dni/Cuit: {items[0].CuitDni_cli} 
                            </label>
                            <p>
                                Dirección: {items[0].Domicilio_cli}, Localidad: {items[0].Localidad_cli}, Provincia: {items[0].Provincia_cli}
                            </p>
                            <p>
                                Telefono: {items[0].Telefono_cli}, Email: {items[0].Email_cli}
                            </p>
                            {/* Puedes mostrar aquí cualquier información común para todas las filas del mismo lote */}
                        </div>
                    :''}
                </div>

                <div
                    id={`collapse-${lote}`}
                    className="collapse"
                    aria-labelledby={`heading-${lote}`}
                    data-parent="#accordionExample"
                >
                    <div className="card-body">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Artículo</th>
                            <th>Descripción</th>
                            <th>Cantidad</th>
                            <th>Falla</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item, idx) => (
                            <tr key={idx}>
                            <td>{item.Producto}</td>
                            <td>{item.Articulo}</td>
                            <td>{item.Descripcion}</td>
                            <td>{item.Cantidad}</td>
                            <td>{item.Falla}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <button className='btn btn-success col-12 mb-2' onClick={() => {handleClick(items)}}>Autorizar</button>
                    <button className="btn btn-secondary col-12 mb-2" onClick={() => {handleClickState(items, "En consulta")}}>Consulta</button>
                    <button className="btn btn-warning col-12 mb-2" onClick={() => {handleClickState(items, "Cancelado")}}>Cancelar</button>
                    </div>
                </div>
                </div>
            </div>
            ))}
            </div>
        </div>
    )
}