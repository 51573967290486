import React, {useState, useEffect} from 'react'
import nuevodocumento from '../images/nuevo-documento.png'
import {Table} from './table'
import {columnsOrdenesTaller, columnsRepuestosoperacion} from './columns';
import {GetOrders} from './dbfirebase'
import imgfullscreen from '../images/fullscreen.png'
import imgclosefullscreen from '../images/closefullscreen.png'
import {Ayuda} from './Help'
import {db} from "../components/firebase";
import {doc, getDoc, setDoc, addDoc, collection, 
    onSnapshot, query, deleteDoc, updateDoc, 
    arrayUnion, FieldValue, serverTimestamp, 
    getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
import { useDispatch } from 'react-redux';
import { addTaller } from '../store/orderInfo/tallerInfo';
import { DeltaTimesChart}  from '../components/DeltaTimesChart';

export const OrdenesTaller = () =>{

    const [dato, setDatos] = useState('')
//    console.log(dato)
    const [rtodato, setRtoDato] = useState('')

    const dispatch = useDispatch();
    
    const [FirstValue,setFirstValue] = useState('');
  
 
    //Funcion que esta a la escucha de los datos en firebase, solo lo pude hacer correr aqui.
    useEffect(()=>{ 
        const actualdate = new Date()
        const prevdate = new Date(actualdate)
        prevdate.setDate(actualdate.getDate() - 90)

        let unsubscribe = null

        const fetchData = async () => {
            try {
                const docRef = query(collection(db, 'OrdenesTaller'), where("TimeStamp", ">", prevdate));
                unsubscribe = onSnapshot(docRef, (query) => {
                    let datos = [];
                    query.forEach(doc => {
                        const d = doc.data()
                        const i = {'Id': doc.id}
                        datos.push({...d, ...i})
                    });
                    
                     const dato = datos.map (item => ({
                         ...item,
                         TimeStamp:item.TimeStamp.seconds
                       }));
                        //elimina timestamp 
                            const data = datos.map(item => {
                            const { TimeStamp, ...rest } = item;
                          
                            // Asigna 'no asignado' a las propiedades undefined
                            const updatedItem = Object.entries(rest).reduce((acc, [key, value]) => {
                              acc[key] = value !== undefined || value === '-' ? value : 'no asignado';
                              return acc;
                            }, {});
                          
                            return updatedItem;
                          });
                    
                           // Filtro
                           // Filtra los datos antes de que se reendericen en la tabla 
                          const firstLevelFilter = dato.filter((datos) =>
                          datos.Cliente.toUpperCase().includes(FirstValue.toUpperCase()) 
                        );

                     


                    setDatos(firstLevelFilter)
                            

                   dispatch(addTaller(data))
                            
                  
                })
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
        
        return () => {
            if (unsubscribe){
                unsubscribe()
            }
        }
    },[FirstValue])
    
    const rtos = () =>{
        let objetosRepuestos = [];
        dato.map((dato) =>{
            if (dato.Repuestos) {            
                // Recorrer la lista de repuestos
                dato.Repuestos.forEach(repuesto => {
                  // Crear un objeto para cada repuesto
                  let objetoRepuesto = {
                    Fecha: new Date(dato.TimeStamp * 1000).toLocaleDateString(),
                    Repuesto: repuesto,
                    OperacionAlta: dato.Oper_Alta_repuesto,
                    Remito: dato.NumRemito,
                    Articulo: dato.Articulo,
                    Orden: dato.iddoc,
                  };
              
                  // Agregar el objeto al array
                  objetosRepuestos.push(objetoRepuesto);
                });
            } 
        })
          
        return objetosRepuestos
    }
 
    const CalculateDate = (date, days) =>{
        date.setDate(date.getDate() - days)
        return date
    }
    
    const hoy = new Date().toLocaleString().split(',')[0]
    
    const pendingOrders = dato && dato.filter((value) => value.Estado !== 'Finalizado' || value.Estado !== 'cancelado')
    const UnchangedOrders = dato && dato.filter((value) => value.Estado === 'Pendiente')
    const qtyOfTodayOrders = dato && pendingOrders.filter((value) => new Date(value.TimeStamp * 1000).toLocaleString().split(',')[0] === hoy).length
   
    // const fiveDaysAgo = new Date();
    // fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);

    const fiveDaysAgo = new Date();
    let daysToSubtract01 = 3;
    let i = 0;

    while (i < daysToSubtract01) {
        fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 1);

        const dayOfWeek01 = fiveDaysAgo.getDay();
        if (dayOfWeek01 !== 0 && dayOfWeek01 !== 6) {
            i++;
        }
    }

    const tenDaysAgo = new Date();
    let daysToSubtract02 = 5;
    let j = 0;

    while (j < daysToSubtract02) {
        tenDaysAgo.setDate(tenDaysAgo.getDate() - 1);

        const dayOfWeek02 = tenDaysAgo.getDay();
        if (dayOfWeek02 !== 0 && dayOfWeek02 !== 6) {
            j++;
        }
    }

    const qtyOfOrdsOverFiveDays = dato && UnchangedOrders.filter((value) => {
        const orderDate = new Date(value.TimeStamp * 1000);
        const orderTime = orderDate.getTime();
        return orderTime <= fiveDaysAgo.getTime() && orderTime > tenDaysAgo.getTime();
      }).length;
    
    const qtyOfOrdsOverTenDays = dato && UnchangedOrders.filter((value) => {
        const orderDate = new Date(value.TimeStamp * 1000);   
        return orderDate.getTime() <= tenDaysAgo.getTime();
      }).length;   


    const talleres = {}

    
    dato && dato.forEach(({TimeStamp, Titular}) =>{
        const date = new Date(TimeStamp * 1000);
        if (date >= tenDaysAgo) {
            if (!talleres[Titular]) {
                talleres[Titular] = 0;
            }
            talleres[Titular]++;
            
        } 

    })

    const SixMonthsAgo = new Date();
    SixMonthsAgo.setDate(SixMonthsAgo.getDate() - 180);

    const talleresCalificacion = {}

    dato && dato.forEach(({TimeStamp, Titular, Calificacion}) =>{
        const date = new Date(TimeStamp * 1000);
        if (date >= SixMonthsAgo) {
            
            if (!talleresCalificacion[Titular]) {
                talleresCalificacion[Titular] = { sum: 0, count: 0 };
            }
            if (Calificacion) {
                talleresCalificacion[Titular].sum += Number(Calificacion);
                talleresCalificacion[Titular].count++;
            }
        }
    })
    
    // Para calcular la media de cada RazonSocial:
    const talleresMediaCalificacion = {}
    
    for (let Taller in talleresCalificacion) {
        const { sum, count } = talleresCalificacion[Taller]; 
        talleresMediaCalificacion[Taller] = sum / (count === 0? 1: count);
    }

    const toggleFullscreen = (ID) =>{
        document.getElementById(ID).requestFullscreen()
        document.getElementById("inp").style.display = 'none'
    }

    const todayDate = new Date().toLocaleDateString(); // Obtiene la fecha actual en formato de cadena
    // const ahora = new Date(); // Hora actual

    // Filtra las órdenes del día de hoy
    const todaysOrders = dato && pendingOrders.filter((value) => {
        const orderDate = new Date(value.TimeStamp * 1000).toLocaleDateString();
        return orderDate === todayDate;
    });

    // Ordena las órdenes por fecha de timestamp
    const sortedOrders = todaysOrders && todaysOrders.sort((a, b) => a.TimeStamp - b.TimeStamp);

    // Calcula el delta time entre cada par consecutivo de órdenes
    const deltaTimes = sortedOrders && sortedOrders.slice(1).map((order, index) => {
        const fechaCargaPrev = new Date(sortedOrders[index].TimeStamp * 1000);
        const fechaCargaActual = new Date(order.TimeStamp * 1000);
        return (fechaCargaActual - fechaCargaPrev) / (1000 * 60); // Diferencia en minutos
    });
    
    // Calcula la media y la desviación estándar del delta time
    const calcularMedia = (arr) => arr.reduce((sum, val) => sum + val, 0) / arr.length;

    const calcularDesviacionEstandar = (arr, media) => {
        const variance = arr.reduce((sum, val) => sum + Math.pow(val - media, 2), 0) / arr.length;
        return Math.sqrt(variance);
    };

    const deltaTimeMedia = deltaTimes && deltaTimes.length > 0 
        ? calcularMedia(deltaTimes) 
        : 0;

    const deltaTimeDesviacionEstandar = deltaTimes && deltaTimes.length > 0 
        ? calcularDesviacionEstandar(deltaTimes, deltaTimeMedia) 
        : 0;

    const calcularMediana = (arr) => {
        const sortedArr = [...arr].sort((a, b) => a - b);
        const middle = Math.floor(sortedArr.length / 2);
        if (sortedArr.length % 2 === 0) {
            return (sortedArr[middle - 1] + sortedArr[middle]) / 2;
        }
        return sortedArr[middle];
    };
    
    const calcularPercentil = (arr, percentil) => {
        const sortedArr = [...arr].sort((a, b) => a - b);
        const index = Math.ceil((percentil / 100) * sortedArr.length) - 1;
        return sortedArr[index];
    };

    const mediana = calcularMediana(deltaTimes);
    const Q1 = calcularPercentil(deltaTimes, 25);
    const Q3 = calcularPercentil(deltaTimes, 75);
    
    // console.log(`Mediana: ${mediana}`);
    // console.log(`Q1: ${Q1}`);
    // console.log(`Q3: ${Q3}`);

    // console.log(`Delta Time Media: ${deltaTimeMedia} minutos`);
    // console.log(`Delta Time Desviación Estándar: ${deltaTimeDesviacionEstandar} minutos`);
    
    return(
        <div>
            <div className='row'>
                
                <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Ordenes de taller</h3>
                    <Ayuda section="seguimientoDeTaller"/>
            </div>
            {/* Indicadores de estado */}
            <div className= 'row d-flex justify-content-center'style={{marginTop: '50px'}}>
                <div className="col-4 card text-bg-success mb-3"style={{width: '10rem', margin:'0 80px'}}  >
                    <div className='card-body'>
                        <h2 style={{textAlign: 'center'}}>{qtyOfTodayOrders}</h2>
                    </div>
                    <div className="card-footer" style={{textAlign: 'center'}}>
                        Operaciones del dia
                    </div>
                </div>
                {deltaTimes && <div className="col card mb-3" style={{width:"20rem", margin:'0 80px'}}>
                    {/* <div className='card-body'>
                       <DeltaTimesChart
                            deltaTimes={deltaTimes}
                            media={deltaTimeMedia}
                            desviacionEstandar={deltaTimeDesviacionEstandar}
                            mediana={mediana}
                            Q1={Q1}
                            Q3={Q3}
                        />
                    </div> */}
                    {/* <div className="card-footer" style={{textAlign: 'center'}}>
                        Escanear ordenes
                    </div> */}
                </div>}
                {/* <div className="col-4 card text-bg-danger mb-3" style={{width: '10rem', margin:'0 80px'}}>
                    <div className='card-body'>
                        <h2 style={{textAlign: 'center'}}>{qtyOfOrdsOverTenDays}</h2>
                    </div>
                    <div className="card-footer" style={{textAlign: 'center'}}>
                        Pendientes mayores a 5 dias
                    </div>
                </div> */}
                <div className="col-4 card text-bg-secondary mb-3" style={{width: '30rem',  margin:'0 80px'}}>
                    <div className="card-header">Volumen de trabajo en los ultimos 5 dias habiles:</div>
                    <div className='card-body'>
                        <ul>
                            {talleres && Object.keys(talleres).map(key => (
                                <li key={key}>
                                    {key + " >> " + talleres[key] + `${talleres[key] > 1 ? " Unidades.":" Unidad."}`}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/* <div className="col-4 card text-bg-secondary mb-3" style={{width: '50rem',  margin:'0 80px'}}>
                    <div className="card-header">Promedio calificaciones de los ultumos 6 meses:
                    </div>
                        <div className='card-body'>
                            <ul>
                                {talleresMediaCalificacion && Object.keys(talleresMediaCalificacion).map(key => (
                                    <li key={key}>
                                        {key + ">> Calificacion de: >> " + talleresMediaCalificacion[key] + '/5 '}
                                    </li>
                                ))}
                            </ul>
                        </div>
                </div> */}
            </div>
            {/* tabla con datos */}
            <h4 style={{marginTop: '50px'}}>Datos de operaciones</h4>
            <div className="card mx-3" style={{marginTop: '50px',  border: 'none'}} id="A1">
            <div >
                    <span style={{paddingLeft:'30px'}}>
                        Buscar Cliente:{' '}
                            <input
                                value={FirstValue || ''}
                                onChange={(e) => setFirstValue(e.target.value)}
                                list="artOptions"
                                style={{ width: '15rem' }}
                            />
                            <datalist id="artOptions">
                                {dato && dato.length > 0 &&
                                    [...new Set(dato.map(item => item.Cliente))]
                                        .map((Cliente, index) => (
                                            <option key={index} value={Cliente} />
                                        ))
                                }
                            </datalist>
                            
                        </span>
                       
                    </div>
                <div className='d-flex justify-content-end' id="inp">                 
                    <input type="image" onClick={() => toggleFullscreen('A1')} style={{border: "0px",width:'2vh', height:'2vh', textAlign: 'center'}} src={imgfullscreen}/>
                </div>
                {dato && <Table showDetail={false} exporttable={true} showComponents={true} showDelete={false} listaOrdenes={dato} source={"orden"} settingcolumns={columnsOrdenesTaller} fiveDaysAgo={fiveDaysAgo} filterState={false} filterDate={false} rows={dato.length} showEditTaller={true} filter={true}/>}
            </div>
            <h4 style={{marginTop: '50px'}}>Datos de repuestos</h4>
            <div className="card mx-3" style={{marginTop: '50px',  border: 'none'}} id="A1">
                <div className='d-flex justify-content-end' id="inp">
                    <input type="image" onClick={() => toggleFullscreen('A1')} style={{border: "0px",width:'2vh', height:'2vh', textAlign: 'center'}} src={imgfullscreen}/>             
                </div>
                {dato && <Table showDetail={false} exporttable={true} showComponents={true} showDelete={false} listaOrdenes={rtos()} source={"orden"} settingcolumns={columnsRepuestosoperacion} fiveDaysAgo={fiveDaysAgo}  filter={true} filterState={false} filterDate={false} rows={rtos().length}/>}
            </div>



        </div>
    )
}