import React, {useState, useRef, useEffect} from 'react'
import { useReactToPrint } from 'react-to-print';
import impresora from '../images/impresora.png'
import factura from '../images/factura.png'
import ServicesPostventaLogo from '../images/ServicesPostventaLogo.PNG'
import { useSelector } from 'react-redux';
import { addMdoCost } from './dbfirebase'
import { ToastContainer, toast } from 'react-toastify';

export const MdoModal = (data) =>{

    const [fieldValues, setFieldValues] = useState()
    const [inputValue, setInputValue] = useState('')

    const mdoref = useRef(null)
    
    // Función para manejar el cambio de los inputs
    const handleInputChange = (e, fieldLabel) => {
        const { value } = e.target;
        const numericValue = value === '' ? 0 : parseFloat(value);
       
        // Actualizamos solo el producto específico dentro del array
        setFieldValues((prevState) =>
            prevState.map(item => 
                item.name === fieldLabel
                ? { ...item, cost: numericValue } // Actualizamos el costo si coincide el nombre
                : item // Si no coincide, dejamos el producto tal cual
            )
        );
    };

    const handleInputChangePorcent = () => {
        const numericValue = inputValue === '' ? 1 : parseFloat(inputValue) / 100; // Dividir por 100

        // Actualizamos todos los productos multiplicando su costo por el valor ingresado
        setFieldValues((prevState) =>
            prevState.map(item => ({
                ...item, // Mantenemos los demás campos sin cambios
                cost: item.cost * (1+numericValue) // Multiplicamos el costo por el valor
            }))
        );

        // Limpiar el input después de aplicar el cambio (opcional)
        setInputValue('');
    };

    useEffect(() => {
        setFieldValues(data.data)
    },[data])

    const sendvalues = async() => {
        const date = new Date();
        const formattedDate = date.toLocaleDateString('es-ES').split('/').join('-'); // Formato por defecto "d/m/a"
        const transformedData = fieldValues.reduce((acc, item) => {
            acc[item.name] = item.cost; // Asigna el costo al nombre del producto
            return acc; // Retorna el acumulador
        }, {});
        
        await addMdoCost({[formattedDate]:[transformedData]})
        // toast.success('Datos cargados con exito',{
        //     position: "top-left",
        //     autoClose: 2000,
        //     hideProgressBar: false,
        //     closeOnClick: true})
        closeModal()
    }
    
    const closeModal = () => {
        const modal = document.getElementById('ModalMDO');
        const backdrop = document.querySelector('.modal-backdrop');
        
        if(mdoref.current.classList.contains('show')){           
            modal.classList.remove('show');
            modal.style.display = 'none'
            document.body.classList.remove('modal-open');
            modal.classList.add('show');
        }
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = 'auto';
    };

    const handleCloseModal = () => {
        closeModal();
    };

    window.history.pushState({}, '', '');

    useEffect(() => {

        const handlePopstate = () => {
            if (mdoref.current.classList.contains('show')) {
                closeModal()
            }
        };
      
        window.addEventListener('popstate', handlePopstate);
      
        return () => {
          
            window.removeEventListener('popstate', handlePopstate);
        };
        
    },[]);

    const getPageMargins = () => {
        return `@page { margin: ${'1cm'} ${'1cm'} ${'1cm'} ${'1cm'} !important; }`;
      };
      
    //   const formData = [
    //     { label: 'Ingrese un número:', type: 'number', placeholder: 'Ejemplo: 10', required: true },
    //     { label: 'Ingrese un texto:', type: 'text', placeholder: 'Ejemplo: Texto aquí', required: true },
    //     // Puedes agregar más campos aquí
    // ];

    // const formData = (data.data && Array.isArray(data.data)) ? fieldValues.map(item => ({
    const formData = fieldValues ? fieldValues.map(item => ({
        label: `${item.name}`,  // Usa el name como parte del label
        type: 'number',
        placeholder: 'Valor actual: $' + item.cost,
        required: true,
        defaultValue: item.cost,  // Agregar valor predeterminado
    })) : [];  // Si no está definido, usar un array vacío
    
    return(
    <div className="modal"
                id="ModalMDO"
                tabIndex={-1}
                aria-labelledby="ModalLabel"
                aria-hidden="true"
                style={{marginBottom:'20px' ,marginTop:'20px', overflow: "auto"}} ref={mdoref}>
                    <ToastContainer/>
        <div className="modal-dialog modal-fullscreen-lg-down modal-lg">

            <div className='container modal-content' style={{overflowX: "auto"}} id='closemodal'>
            
                    <div className="modal-header">
                        <h5 className="modal-title" id="ModalLabel">Generar nueva lista de precios</h5>
                    </div>
                    <div className="modal-body">
                        {/* <label className="form-label">Aplicar ajuste sobre el total de items</label>
                        <div className="input-group mb-3">
                            <input type="number" className="form-control" placeholder="Ingresar el valor en porcentaje Ej. 50" value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
                            <div className="input-group-append">
                                <button className="btn btn-outline-secondary" type="button" onClick={handleInputChangePorcent} >OK</button>
                            </div>
                        </div> */}
                        <form>
                            {formData && formData.map((field, index) => (
                                <div className= 'row' key={`input-${index}`}>
                                    <div className="col mb-3" >
                                        <label htmlFor={`inputs-${index}`} className="form-label d-block">{field.label}</label>
                                        <label htmlFor={`inputs-${index}`} className="form-label d-block">{field.placeholder}</label>
                                    </div>
                                    <div className="col-4 mb-3" >
                                        <label htmlFor={`inputm-${index}`} className="form-label">Asignar nuevo valor</label>
                                        <div className="row">
                                            <input
                                                type={field.type}
                                                className="col form-control"
                                                id={`inputm-${index}`}
                                                placeholder="Ingresar valor en $"
                                                // value={fieldValues && fieldValues?.name[field.label] || ''} // Usamos el estado fieldValues para el valor
                                                onChange={(e) => handleInputChange(e, field.label)} // Capturamos cambios
                                                // required={field.required}
                                            />
                                            {/* <button className="col-4 btn btn-outline-secondary" onClick={() => {console.log(field)}} type="button">OK</button> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <button type="button" className="btn btn-primary" onClick={() => sendvalues() }>Enviar</button>
                        </form>
                    </div>
                </div>
            
        </div>
    </div>
    )
}